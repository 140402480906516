<!--
 * @Description: 供应商订单详情
 * @Author: zhang zhen
 * @Date: 2023-02-15 16:41:48
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-16 09:00:17
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/orderDetails.vue
-->
<template>
  <div>
    <div class="ForUserAndPurchaseDetails info-container-box">
      <div class="info-area-top">
        <div class="left-side">
          <div class="ForUserAndPurchaseDetails-title">{{ orderTitle }}</div>
          <div class="ForUserAndPurchaseDetails-subtitle">
            <div class="header-options">
              <span class="label">订单编号：{{ orderNo }}</span>
              <a class="copy" @click="handleCopy(orderNo)">复制</a>
            </div>
          </div>
        </div>
        <a-space class="action-part">
          <a-button ghost type="primary" @click="handleChat">
            <div class="btn-inner">
              <img src="~@/assets/message-3-line.png" alt="" class="icon" />
              <span class="text">咨询TA</span>
            </div>
          </a-button>
          <a-button type="primary" @click="handleAuditReturn" v-if="afterSaleStatus == 1">定金退款审核</a-button>
          <a-button type="primary" @click="handleAuditReturnMoney" v-if="afterSaleStatus == 2">退款审核</a-button>
          <template v-if="afterSaleStatus == 4">
            <a-button type="primary" @click="handleAuditExchange" v-if="exchangeStatus == 0">换货审核</a-button>
            <!-- 第一条exchangeStatus==1 则按钮置灰展示文本 【换货处理中：等待买家寄出商品】 -->
            <a-button type="primary" v-if="exchangeStatus == 1" :disabled="true">换货处理中：等待买家寄出商品</a-button>
            <!-- 如果返回结果的exchangeStatus==2，给供应商展示发出新商品的页面文本【采购商寄出，等待换货发货】，点击后调用：/afterSale/exchange/sendNewGoods -->
            <a-button type="primary"v-if="exchangeStatus == 2" @click="handleSendNewGoods">采购商寄出，等待换货发货</a-button>
            <!-- 如果如果返回结果的exchangeStatus==3 按钮置灰文本【新商品已发货，等待确认】 -->
            <a-button type="primary" :disabled="true" v-if="exchangeStatus == 3">新商品已发货，等待确认</a-button>
          </template>
          <a-button type="primary" icon="close-circle" v-if="cancelOrder" @click="showRemoveOrder = true"
            >取消订单</a-button
          >
        </a-space>
      </div>
      <a-tabs v-model="activeTab" @change="handleChangeTabs">
        <a-tab-pane key="2" tab="详情">
          <RequirementInfo
            ref="RequirementInfoRef"
            :needSteps="true"
            :showOrderInfo="false"
            :purchaseId="purchaseId"
            :needLoadNow="true"
            :needMoreInfo="false"
            @updateOrderInfo="handleEmitUpdate"
          />
        </a-tab-pane>
        <a-tab-pane key="1" tab="概览">
          <SupplierInfo ref="SupplierInfo" :orderNo="orderNo" />
        </a-tab-pane>

        <a-tab-pane key="6" tab="单据">
          <BillUploader ref="BillUploader" :orderNo="orderNo" :orderStatus="orderStatus" @ok="handleLoadInfo" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="协议">
          <ContractPart
            ref="ContractPart"
            :orderNo="orderNo"
            :orderStatus="orderStatus"
            fileKey="contractFileSupplier"
          />
          <!-- <FileListView ref="FileListViewRef" /> -->
        </a-tab-pane>
        <a-tab-pane key="7" tab="评价">
          <AppraisalInfo :orderNo="orderNo" :needReplay="true" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- <InterestedCard /> -->
    <a-modal
      title="温馨提示"
      okText="确认"
      :visible="showRemoveOrder"
      centered
      @ok="handleRemoveOrder"
      @cancel="showRemoveOrder = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您确定取消订单吗?</span>
      </div>
    </a-modal>
    <!-- 审核退定金 -->
    <AuditReturnDeposit ref="AuditReturnDepositRef" @ok="handleLoadInfo" />
    <!-- 审核退款 -->
    <AuditReturnMoney ref="AuditReturnMoneyRef" @ok="handleLoadInfo" />
    <!-- 换货审核 -->
    <AuditExchange ref="AuditExchangeRef" @ok="handleLoadInfo" />
    <!-- 换货发货 -->
     <EnterAuditReturnInfo ref="EnterAuditReturnInfoRef" @ok="handleLoadInfo" />
  </div>
</template>

<script>
import InterestedCard from '@/components/tools/interestedCard.vue'
import BasicInfo from './children/basicInfo.vue'
import UploadBills from './children/uploadBills.vue'
import ContractPart from './children/contractPart.vue'
import FacilitatorBills from './children/facilitatorBills.vue'
import UploadProgress from './children/UploadProgress.vue'
import SupplierInfo from './children/supplierInfoForUser.vue'
import SupplierUploadProgress from './children/supplierUploadProgress.vue'
import BillUploader from './children/billUploader.vue'
import InfoDetails from './children/infoDetails.vue'
import AppraisalInfo from './children/appraisalInfo.vue'
import RequirementInfo from '@/components/plugins/requirementOrderInfo.vue'
import AuditReturnDeposit from './children/AuditReturnDeposit.vue'
import { getAction, postAction, putAction } from '@/api/manage'
import AuditReturnMoney from './children/AuditReturnMoney.vue'
import AuditExchange from './children/AuditExchange.vue'
import EnterAuditReturnInfo from './children/enterAuditReturnInfo.vue'
export default {
  name: 'orderDetails',
  components: {
    RequirementInfo,
    InterestedCard,
    BasicInfo,
    UploadBills,
    ContractPart,
    FacilitatorBills,
    UploadProgress,
    SupplierInfo,
    SupplierUploadProgress,
    BillUploader,
    InfoDetails,
    AppraisalInfo,
    AuditReturnDeposit,
    AuditReturnMoney,
    AuditExchange,
    EnterAuditReturnInfo
  },
  data() {
    return {
      showRemoveOrder: false,
      activeTab: '2',
      orderNo: '',
      orderTitle: '',
      infoType: '3',
      orderStatus: null,
      purchaseId: null,
      purchaseNo: null,
      cancelOrder: false,
      afterSaleStatus: '',
      paymentMethod: '',
      exchangeStatus: null,
      orderItems: {},
    }
  },
  created() {
    const { orderNo, orderTitle, purchaseId, hasEdit } = this.$route.query
    orderTitle && (this.orderTitle = orderTitle)
    orderNo && (this.orderNo = orderNo)
    hasEdit && (this.hasEdit = hasEdit == true)
    this.handleLoadExchangeRecord();
  },
  methods: {
    // 换货状态
    handleLoadExchangeRecord() {
      postAction('/refund/exchange/queryByOrderNo', {
        orderNo: this.orderNo,
      }).then((res) => {
        const { success, data, message } = res
        if (success && data[0]) {
          const { exchangeStatus } = data[0]
          this.exchangeStatus = exchangeStatus
        }
      })
    },
    handleChat() {
      this.$refs.RequirementInfoRef.handleEmitChat()
    },
    handleSendNewGoods() {
      this.$refs.EnterAuditReturnInfoRef.handleOpen(this.orderNo, this.orderItems)
    },
    /* 复制单号 */
    handleCopy(code) {
      const _input = document.createElement('input') // 直接构建input
      _input.value = code // 设置内容
      document.body.appendChild(_input) // 添加临时实例
      _input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(_input) // 删除临时实例
      this.$message.success('复制成功')
    },
    handleLoadInfo() {
      location.reload()
    },
    handleChangeTabs(e) {
      this.$nextTick((_) => {
        if (e == '1') {
          this.$refs.SupplierInfo.handleLoadInfo()
        } else if (e == '3') {
          this.$refs.ContractPart.handleLoadInfo()
        } else if (e == '6') {
          this.$refs.BillUploader.handleLoadTransportInfo(this.orderNo)
        }
      })
    },
    handleSwitchNav(url) {
      this.$router.push(url)
    },
    /* 确认按钮 */
    handleOK() {
      // 0-待签署，1-已签署，2-已付款，3-生产中，4-已发货，5-运输中，6-已收货，7-已完结，-1已取消
      if (this.orderStatus == '0') {
        // 提交订单状态为已签署
        postAction('/order/sign', {
          orderNo: this.orderNo,
        }).then((res) => {
          const { success, message, data } = res
          if (success) {
            // this.$message.success(message)
            this.handleLoadInfo()
          } else {
            this.$message.warning(message)
          }
        })
      } else if (this.orderStatus == '1') {
        // 提交订单状态为已付款
        postAction('/order/payed', {
          orderNo: this.orderNo,
        }).then((res) => {
          const { success, message, data } = res
          if (success) {
            // this.$message.success(message)
            this.handleLoadInfo()
          } else {
            this.$message.warning(message)
          }
        })
      } else if (this.orderStatus == '3') {
        // 提交订单状态为已发货
        postAction('/order/shipped', {
          orderNo: this.orderNo,
        }).then((res) => {
          const { success, message, data } = res
          if (success) {
            // this.$message.success(message)
            this.handleLoadInfo()
          } else {
            this.$message.warning(message)
          }
        })
      } else if (this.orderStatus == '4') {
        // 提交订单状态为已收货
        postAction('/order/transported', {
          orderNo: this.orderNo,
        }).then((res) => {
          const { success, message, data } = res
          if (success) {
            // this.$message.success(message)
            this.handleLoadInfo()
          } else {
            this.$message.warning(message)
          }
        })
      } else if (this.orderStatus == '6') {
        // 提交订单状态为已完结
        postAction('/order/finished', {
          orderNo: this.orderNo,
        }).then((res) => {
          const { success, message, data } = res
          if (success) {
            // this.$message.success(message)
            this.handleLoadInfo()
          } else {
            this.$message.warning(message)
          }
        })
      }
      this.handleChangeTabs(this.activeTab)
    },
    /* 取消订单 */
    handleRemoveOrder() {
      putAction('/order/remove', {
        orderNo: this.orderNo,
      }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$router.go(-1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleEmitUpdate({ cancelOrder, paymentMethod, afterSaleStatus, orderItems }) {
      this.cancelOrder = cancelOrder
      this.paymentMethod = paymentMethod
      this.afterSaleStatus = afterSaleStatus
      this.orderItems = orderItems
    },
    /* 审核订单 */
    handleAuditReturn() {
      this.$refs.AuditReturnDepositRef.handleEdit(this.orderNo)
    },
    handleAuditReturnMoney() {
      this.$refs.AuditReturnMoneyRef.handleEdit(this.orderNo)
    },
    /* 换货审核 */
    handleAuditExchange() {
      this.$refs.AuditExchangeRef.handleEdit(this.orderNo)
    },
  },
}
</script>

<style lang="less" scoped>
.ForUserAndPurchaseDetails {
  min-height: 580px;

  ::v-deep.ant-tabs {
    color: rgba(0, 0, 0, 0.85);
  }

  &-title {
    color: #090b12;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &-subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    grid-gap: 0 30px;

    .copy {
      font-size: 13px;
      margin-left: 8px;
      font-weight: 500;
    }
  }

  .restPart {
    text-align: right;
    flex: 1 0 0;
    min-width: 0;
  }
}

::v-deep .ant-tabs-tab-active {
  font-weight: 500;
}

.btns {
  text-align: center;
}

@import '~@/styles/detail.less';
.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

.info-area-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-part {
  .btn-inner {
    display: flex;
    align-items: center;

    .icon {
      width: 18px;
      margin-right: 8px;
    }

    span.text {
      color: #ff6026;
      font-size: 14px;
    }
  }

  ::v-deep .ant-btn {
    min-width: 103px;
    height: 38px;
    font-weight: 500;
  }
}
</style>
